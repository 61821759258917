import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { firebase } from '../firebaseClient';
import { useSession } from '../lib/auth';
import Loading from '../components/Loading/Loading';

const Login: React.FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<'google' | 'email'>();
  const [userEmailErrors, setUserEmailErrors] = useState('');
  const { user, loading: sessionLoading, authenticated } = useSession();
  const router = useRouter();

  const loginUserWithEmail = async () => {
    setLoading('email');

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      setUserEmailErrors(error.message);
      setLoading(undefined);
    }
  };

  const loginUserWithGoogle = async () => {
    setLoading('google');

    try {
      await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    } catch (e) {
      setLoading(undefined);
    }
  };

  if (authenticated) {
    const { returnPath } = router.query;

    const redirect = user.organization?.slug
      ? returnPath
        ? decodeURIComponent(returnPath as string)
        : `/org/${user.organization.slug}`
      : '/onboarding';

    router.push(redirect);
  }

  return (
    <div className="flex flex-col items-center justify-center mx-auto bg-indigo-400 text-white min-h-screen py-12">
      {sessionLoading || authenticated ? (
        <Loading />
      ) : (
        <div className="w-full px-4 md:w-1/4">
          <Link href="/">
            <a>
              <img
                className="w-80 pb-10 block mx-auto"
                src="/images/logo/lampant.png"
                alt=""
              />
            </a>
          </Link>

          <div>
            <div className="text-xs mb-2">
              <p>Your email</p>
              {userEmailErrors.length ? <p>{userEmailErrors}</p> : ''}
            </div>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-md w-full h-12 border-2 border-indigo-50 text-indigo-700 px-2"
            />

            <div className="text-xs my-2">
              <p>Your Password</p>
            </div>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="rounded-md w-full h-12 text-2xl border-2 border-indigo-50 text-indigo-700 px-2"
            />

            <div className="rounded-md shadow mt-6">
              <button
                type="button"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-600 md:py-4 md:text-lg md:px-10"
                onClick={loginUserWithEmail}
                disabled={loading !== undefined}
              >
                {loading === 'email' ? 'Loading...' : 'Login'}
              </button>
            </div>
          </div>

          <div className="flex flex-row items-center mt-4">
            <div className="border-indigo-100 border-b-2 flex-1" />
            <span className="px-4 block">or</span>
            <div className="border-indigo-100 border-b-2 flex-1" />
          </div>

          <div className="rounded-md shadow mt-6">
            <button
              type="button"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
              onClick={loginUserWithGoogle}
              disabled={loading !== undefined}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 48 48"
                className="abcRioButtonSvg"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                />
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                />
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                />
                <path fill="none" d="M0 0h48v48H0z" />
              </svg>
              <span className="px-2">
                {loading === 'google' ? 'Loading' : 'Sign in with Google'}
              </span>
            </button>
          </div>

          <div className="text-xs text-center mt-10">
            <p className="p-2">
              By clicking the button, you agree to our{' '}
              <a href="/terms" className="text-yellow-400">
                Terms of Service
              </a>{' '}
              and have read and acknowledge our{' '}
              <a className="text-yellow-400" href="/privacy">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
